import React from 'react'
import Layout from '../components/layout'
import { Container, Row, Col } from 'reactstrap'
import banner from '../images/who-we-are-banner.jpg'
import coach from '../images/coatch-1.png'
import whoWeAreBg2 from '../images/who-we-are-bg2.jpg'
import { BookAnAppoinment } from '../components/book0an-apoinment'
import { seoData } from '../data/seo-data'
const WhoWeAre = () => {
    return (
        <Layout
            seo={{ title: seoData.whoWeAre.title, description: seoData.whoWeAre.description, keyword: '' }}
            banner={<FreeConsulTationBanner />}
            navStayle="SIMPLE"
            setUid={() => {} }
        >
            <section style={{ marginBottom: 50, marginTop: 100 }}>
                <Container style={{ textAlign: "center" }}>
                    <p>The reason we are in this business is to empower our clients, so that they can take control of their lives. We focus on YOU and the important people around you - our primary focus - rather than product.</p>
                    <p>In order for a client to gain clarity we must first discuss the “what” and the “why”. What do you want? And why do you want it?  Those two questions are a lot harder to answer than most people realise, but once we help you find the answers we can then begin to build a financial strategy that will help you achieve the “what” and the “why”.</p>
                    <p>What do we want?  We want to empower our clients. Why? Because they can then take back control of their lives.</p>
                    <Row style={{ marginTop: 50 }}>
                        <Col style={{ marginTop: '5%' }} md={6}>
                            <p   >Our focus is developing long-term relationships with our clients and coaching them toward their financial goals. Every individual is different, and our solutions are personalised.  </p>
                            <p >If you’re interested in looking at your financial position from a different perspective, give us a call. We would love to hear your story.</p>
                        </Col>
                        <Col md={6}>
                            <img src={coach} alt=""></img>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section style={{ marginBottom: 50, padding: "150px 0 0 0", background: `url(${whoWeAreBg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div style={{ backgroundColor: 'rgba(1, 107, 146, 0.88)', padding: 20, color: '#fff', marginTop: 130 }}>
                                <p style={{ fontWeight: 500 }}><i className="fas fa-quote-left pr-2"></i> Solutions Consultants has brought me a huge benefits, Marc has got my personal finances under control and we have both set a savings goal.  Solutions Consultants helped restructure my superannuation and insurance, and has given me solid basic financial advice. <i className="fas fa-quote-right pr-2" /></p>
                                <p style={{ color: '#d4ca5a', fontWeight: 800 }}>- David Gellie -</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.88)', padding: 20, color: '' }}>
                                <p style={{ fontWeight: 500 }}><i className="fas fa-quote-left pr-2"></i> Solutions takes the worry out of doing it yourself. Working with them takes the confusion away from it. <i className="fas fa-quote-right pr-2" /></p>
                                <p style={{ color: '#15abe3', fontWeight: 800 }}>- Rob and Kim Virgona </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <BookAnAppoinment />
        </Layout>
    )
}

const FreeConsulTationBanner = () => {
    return (
        <>
            <div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'top center', minHeight: 600 }}>
                <Container>
                    <Row className="justify-content-md-center" style={{ paddingBottom: 50, textAlign: "center" }}>
                        <Col md={6}>
                            <div style={{ marginTop: '50%', background: 'rgba(1, 107, 146, 0.75)', padding: '10px 0' }}>
                                <h3 className="header-font" style={{ color: '#fff', fontSize: '5em', marginBottom: 0, width: 'auto' }}>Who We Are</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default WhoWeAre

